.app-container {
  display: flex;
  flex-direction: column;
  background-repeat: repeat;
  background-size: 200% 100%; /* Ajustamos el tamaño para que el fondo se repita */
    
  background: repeating-linear-gradient(
    -145deg, /* Cambiamos la dirección del gradiente para expandirse hacia la derecha */
    #6219ae 0vw,
    #330a80 10vw,
    #330a80 20vw,
    #6219ae 30vw,
    #3c1498 40vw,
    #501498 50vw,
    #371596 60vw,
    #330a80 70vw,
    #330a80 80vw,
    #330a80 90vw,
    #6219ae 100vw /* same as the first color */
  );
  
  background-attachment: fixed;
  width: 100%;
  min-height: 100dvh;
  padding-top: 1rem;
}

/* figure nuevo nati */
.remplazohref {
  text-decoration: none;
  height: 100%;
}

.contenedor {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 250px;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 20px !important;
  background-color: rgba(255,255,255,.15);
  min-height: 250px;
  
}

.contenedor_tarjeta {
  width: 100%;
  min-height: 10rem;
}

figure {
  margin: 0;
  position: relative;
  transition: all ease .5s;
  transform-style: preserve-3d;
  transform: perspective(600px) rotateY(0deg);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
 
  /* min-height: 300px; */
  width: 100%;
}

.contenedor_tarjeta:hover figure img {
  filter: blur (2px);
}

figure .frontal{
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all ease .5s;
  padding: 1rem;
}
figure .trasera {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all ease .5s;
 
 
}

.react-hierarchy-node-container .react-hierarchy-node .node-box:first-child{
  /* background-color: red !important; */
}

.frontal h4 {
  color: white;
  margin-top: 0.8rem;
}

figure .frontal {
  display: block;
  backface-visibility: hidden;
}


figure .trasera {
  position: absolute;
  top: 0;
  min-height: 100%;
  width: fit-content(50%);
  color: #fff;
  transform: perspective(600px) rotateY(180deg);
  backface-visibility: hidden;
  overflow: hidden;
  display: flex;
  align-content: center;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 20px !important;
}

.contenedor_tarjeta:hover figure {
  -webkit-transform: perspective(600px) rotateY(180deg);
  -ms-transform: perspective(600px) rotateY(180deg);
  -o-transform: perspective(600px) rotateY(180deg);
  transform: perspective(600px) rotateY(180deg);
}

.cards-section{
  margin-top: 5rem;
}

.cards-section figure{
  min-height: 300px;
  background: linear-gradient(180deg, rgba(61, 26, 130, 1) 56%, rgba(35, 13, 106, 1) 78%);
  
}


.cards-section .contenedor_tarjeta:hover figure {
  -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
}

.cards-section figure .trasera {
  padding: 4rem;
}


.img-container{
  width: 100%;
}

.img-container img{
  width: 100%;
  height: auto;
}

.title-frontal{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.label-back{
  font-weight: 500;
  font-size: 1rem;
}

.span-back{
  font-weight: 400;
  font-size: .8rem;
}

.flip-button{
  color: cyan;
  height: 1.5rem;
}

.hierarchy-viewer {
  width: auto;
  margin: 0;
  display: flex;
  overflow: auto;
 
}

.react-hierarchy-container .ngx-hierarchy-border {
  border: none !important;
  color: transparent !important;
  box-shadow: none !important;
}


.react-hierarchy-container .react-hierarchy-root-horizontal {
  display: flex;
  width: 100%;
  /* flex-wrap: nowrap; */
  justify-content: center;
}

.react-hierarchy-node-container{
  color: transparent !important;
}

/* .react-hierarchy-container .ngx-hierarchy-border .node-box{
  box-shadow: none;
} */

.navbar-search{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  height: 20vh;
  align-items: center;
  text-align: center;
}

.logo-nav{
  width: 15vw;
}
.logo-nav-mini{
  width: 10rem;
  margin-bottom: 1rem;
}

.logo-nav:hover{
  transition: all .3s ease-in-out; 
  transform: scale(1.1); 
}

.Menu{
  color: white;
  font-weight: bold;
  font-size: 2rem;
  background: linear-gradient(270deg, #ba74f2, #5b77f5);
  padding: .25rem;
  margin: .25rem;
  cursor: pointer;
  border-radius: 15%;
}

.Menu-inverted{
  transform: rotate(90deg);
  color: white;
  font-weight: bold;
  background: linear-gradient(#ba74f2, #5b77f5);
  padding: .25rem;
  margin: .25rem;
  font-size: 2rem;
  cursor: pointer;
  border-radius: 15%;
}

@media (max-width: 768px) {
  .Menu{
    margin: 1rem;
  }
}

.search-bar-container{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

#text{
  color: red !important;
}

.search-bar-nav{
  width: 100%;
  height: 3rem;
  padding: 10px;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  outline: none;
  background-color: transparent;
}

.search-bar-nav::placeholder{
  color: white;
  padding-left: 1.8vw;
}

.search-btn{
  
    color: white;
    border-radius: 0px 20px 20px 0;
    background: linear-gradient(-90deg, #B93EF1, #535CF5);
    padding: 0rem 2rem;
    border: 1px solid white;
    cursor: pointer;
    position: relative;
    right: 10%;
  
}

.search-btn:disabled{
  background: linear-gradient(90deg, #b6b6b6, #444444);

}

.input-search{
  color: white;
  padding: .25rem;
  margin: .25rem;
  background-color: transparent;
  border: 2px solid white;
}

.loader-container{
  height: 79vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.back-container{
  max-width: 100%;
  height: 100%;
  text-align: left;
  font-size: 1rem;
  word-break: break-word;
  padding: 1rem;
}

.back{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.view-btn{
  color: white;
  background: linear-gradient(270deg, #ba74f2, #5b77f5);
  padding: .4rem;
  margin: .25rem;
  width: 80%;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

.first-node{
  background-color: red !important;
}