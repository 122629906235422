.background{
  
  background: repeating-linear-gradient(
    -145deg, /* Cambiamos la dirección del gradiente para expandirse hacia la derecha */
    #6219ae 0vw,
    #330a80 10vw,
    #330a80 20vw,
    #6219ae 30vw,
    #3c1498 40vw,
    #501498 50vw,
    #371596 60vw,
    #330a80 70vw,
    #330a80 80vw,
    #330a80 90vw,
    #6219ae 100vw /* same as the first color */
  );
    background-size: 100% 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.col-text-landing{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.img-landing-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;
}

.container-text {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  margin-left: 5rem;
}


.first-title{
  font-size: 6rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.second-title{
  font-size: 5rem;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
}

.header p {
  font-size: 1.3rem;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
}

.col-dropdown{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;

}

.div-input-btn{
  display: flex;
  
}

.search {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-bar{
  width: 40vw;
  height: 3rem;
  padding: 10px;
  border: 1px solid white;
  border-radius: 20px;
  color: white;
  outline: none;
  background-color: transparent;
}

.search-bar::placeholder{
  color: white;
  padding-left: 2vw;
}

.search-bar-mini::placeholder{
  color: white;
  padding-left: 2vw;
  -moz-padding-left:2vw;
}

.search-bar-mini{
  border: none;
  color: white;
  border-radius: 20px 0 0 20px;
  outline: none;
  background-color: transparent;
  border: 1px solid white;
}

.chart {
  width: 600px;
}

.footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;

}

.footer img {
  width: 25vh;
}
.footer img:hover {
  transition: all .3s ease-in-out; 
  transform: scale(1.1); 
}

.footer p {
  margin-left: 20px;
}

.landing-btn {
  color: white;
  
  border-radius: 0px 20px 20px 0;
  background: linear-gradient(-90deg, #B93EF1, #535CF5);
  padding: 0rem 2rem;
  border: 1px solid white;
  cursor: pointer;
  position: relative;
  right: 10%;
}

.landing-btn:disabled{
  background: #66656B;
}

.landing-btn-mini {
  color: white;
  border: 1px solid white;
  border-radius: 5px 20px 20px 5px;
  background: linear-gradient(90deg, #ba74f2, #5b77f5);
  padding: 0.8rem 1rem;
  cursor: pointer;
}

.landing-btn-mini:disabled{
  background: #66656B;
}
/* 
button:hover {
  transition: all .3s ease-in-out; 
  transform: scale(1.1); 
}
 */




@media (max-width: 767.98px) { 

  .background{
    height: 100%;
    padding-top: 2rem;
    justify-content: flex-start;
  }
  .container-text{
    margin-left: 0;
    text-align: center;
    align-items: center;
    justify-content: flex-start;
  }

  .header{
    text-align: center;
  }

  .header p{
    font-size: 1rem;
    padding: 0 4rem;
  }

  .first-title{
    font-size: 3rem;
  }
  .second-title{
    font-size: 3rem;
  }

  .col-dropdown{
    align-items: center;
  }

  .dropdown select{
    width: 50vw;
    padding-left: 2rem;
  }

  .search-bar-mini{
    width: 75vw;

  }

  .landing-btn-mini{
    position: relative;
    right: 10%;
  }

  .div-input-btn{
    width: 80%;
  }

  .footer{
    width: 100vw;
  }

  .img-mobile{
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 100%;
  }
 }